import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'

// GraphQL
import { getGraphTiers } from '../../../graphQL'

// Utils
import { getFont } from '../../../utils/utils'

// Components
import TierSlide from '../../molecules/TierSlide'

// Hooks
import useWindowSize from '../../../hooks/windowSize'

// Styles
import {
  navPrev,
  navNext,
  navButton,
  shiftingTrans,
  carrouselTitle,
  carrouselBlock,
  carrouselSlider,
  carrouselWrapper,
} from './styles.module.scss'

interface Props {
  title: string
}

const SupportCarrousel: React.FC<Props> = ({ title }) => {
  const { width } = useWindowSize()

  const tiers = getGraphTiers()

  const maxTier = tiers.length

  const [shifting, setShifting] = useState(false)
  const [centerSlide, setCenterSlide] = useState(0)
  const [slideProps, setSlideProps] = useState({ width: 360, offset: 220 })

  const shiftSlide = (steps: number) => {
    if (!shifting) {
      setShifting(true)
      setCenterSlide(centerSlide + steps)
    }
  }

  const renderSlides = () => {
    const slides = [
      <TierSlide key="Slide_second_last" tier={tiers[tiers.length - 2]} />,
      <TierSlide key="Slide_last" tier={tiers[tiers.length - 1]} />,
    ]
    tiers.forEach((tier) => {
      slides.push(<TierSlide key={`Slide_${tier.id}`} tier={tier} />)
    })
    slides.push(<TierSlide key="Slide_first" tier={tiers[0]} />)
    slides.push(<TierSlide key="Slide_second" tier={tiers[1]} />)
    return slides
  }

  useEffect(() => {
    if (width >= 1440) {
      setSlideProps({ width: 360, offset: 224 })
    } else if (width >= 1280) {
      setSlideProps({ width: 310, offset: 179 })
    } else if (width >= 1024) {
      setSlideProps({ width: 300, offset: 282 })
    } else if (width >= 768) {
      setSlideProps({ width: 360, offset: 200 })
    } else if (width >= 640) {
      setSlideProps({ width: 360, offset: 264 })
    } else {
      setSlideProps({ width: 250, offset: 510 })
    }
  }, [width])

  useEffect(() => {
    setTimeout(() => {
      setShifting(false)
      if (centerSlide >= maxTier) {
        setCenterSlide(0)
      }
      if (centerSlide < 0) {
        setCenterSlide(maxTier - 1)
      }
    }, 200)
  }, [centerSlide])

  return (
    <div className={carrouselBlock}>
      <div
        className={`${navButton} ${navNext}`}
        onMouseDown={(event) => {
          event.preventDefault()
        }}
        onClick={(event) => {
          event.preventDefault()
          shiftSlide(1)
        }}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
      <div
        className={`${navButton} ${navPrev}`}
        onMouseDown={(event) => {
          event.preventDefault()
        }}
        onClick={(event) => {
          event.preventDefault()
          shiftSlide(-1)
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <h2 className={`${carrouselTitle} ${getFont('font-patua')}`}>{title}</h2>
      <div className={carrouselWrapper}>
        <div
          className={`${carrouselSlider} ${shifting ? shiftingTrans : ''}`}
          style={{
            left: `${-centerSlide * slideProps.width - slideProps.offset}px`,
          }}
        >
          {renderSlides()}
        </div>
      </div>
    </div>
  )
}

export default SupportCarrousel
