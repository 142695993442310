import React from 'react'
import { navigate } from 'gatsby'
import { faBahai } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Models
import { ChapterModel } from '../../../models'

// Utils
import { getFont } from '../../../utils/utils'

// Styles
import {
  coverImg,
  lastCorner,
  coverSlide,
  pbCorrection,
  topLeftWrapper,
  coverPseudobutton,
} from './styles.module.scss'

interface Props {
  last?: boolean
  localization: { read: string; spin: string; latest: string }
  chapter: Partial<ChapterModel>
}

const CoverSlide: React.FC<Props> = ({ chapter, last, localization }) => {
  return (
    <div
      className={`${coverSlide}`}
      onClick={() => {
        navigate(`/reader/${chapter?.series?.prefix}${chapter.number}`)
      }}
    >
      <img
        className={coverImg}
        src={`https://dilancovak.com/.img/covers/CoverCap${chapter?.series?.prefix}${chapter.number}.jpg`}
      />
      {chapter?.series?.prefix !== '' && (
        <div
          className={`${coverPseudobutton} ${getFont(
            'font-lato',
            pbCorrection
          )}`}
        >
          <FontAwesomeIcon icon={faBahai} /> {localization.spin}
        </div>
      )}
      {last && (
        <div className={topLeftWrapper}>
          <div className={`${lastCorner} ${getFont('font-lato')}`}>
            {localization.latest}
          </div>
        </div>
      )}
    </div>
  )
}

export default CoverSlide
