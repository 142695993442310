// extracted by mini-css-extract-plugin
export var centeredButton = "styles-module--centered-button--F9hC0";
export var centeredText = "styles-module--centered-text--SUSd3";
export var carrouselBlock = "styles-module--carrousel-block--ZybkP";
export var carrouselTitle = "styles-module--carrousel-title--ioDB7";
export var carrouselWrapper = "styles-module--carrousel-wrapper--11j83";
export var carrouselSlider = "styles-module--carrousel-slider--yKFha";
export var shiftingTrans = "styles-module--shifting-trans--FtHrB";
export var navButton = "styles-module--nav-button--ySp-f";
export var navPrev = "styles-module--nav-prev--aHld+";
export var navNext = "styles-module--nav-next--W+rPt";
export var buttonGap = "styles-module--button-gap--DPrmI";
export var pbCorrection = "styles-module--pb-correction--bAYs2";