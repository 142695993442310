import React from 'react'

// Components
import Home from '../components/templates/Home'
import PageTemplate from '../components/templates/PageTemplate'


const IndexPage = () => {
  return (
    <PageTemplate>
      <Home />
    </PageTemplate>
  )
}

export default IndexPage
