import React, { useState } from 'react'
import { navigate } from 'gatsby'

// Hooks
import useInterval from '../../../hooks/interval'

// Utils
import { createPath, getFont } from '../../../utils/utils'

// GraphQL
import { getGraphCharacters } from '../../../graphQL'

// Components
import DilanButton from '../../atoms/DilanButton'

// Styles
import {
  characterImg,
  characterBack,
  characterRight,
  characterTitle,
  characterActive,
  characterInactive,
  characterContainer,
} from './styles.module.scss'

interface Props {
  localization: { title: string; button: string }
}

const CharacterHome: React.FC<Props> = ({ localization }) => {
  const allCharacter = getGraphCharacters()
  const [active, setActive] = useState(true)
  const [front, setFront] = useState(0)
  const [back, setBack] = useState(0)

  useInterval(() => {
    let rand = Math.floor(Math.random() * allCharacter.length)
    if (active) {
      setTimeout(() => {
        setFront(rand)
      }, 2000)
    } else {
      setTimeout(() => {
        setBack(rand)
      }, 2000)
    }
    setActive(!active)
  }, 5000)

  return (
    <div className={characterContainer}>
      <div>
        <h3 className={`${characterTitle} ${getFont('font-patua')}`}>
          {localization.title}
        </h3>
        <DilanButton
          type={'ghost-secondary'}
          className={getFont('font-lato')}
          handleClick={() => {
            navigate(`/world/bios`)
          }}
        >
          {localization.button}
        </DilanButton>
      </div>
      <div className={characterRight}>
        <img
          className={`${characterImg} ${characterBack} ${
            active ? characterInactive : characterActive
          }`}
          onClick={() => {
            navigate(`/world/bios/${createPath(allCharacter[back].name)}`)
          }}
          src={`https://dilancovak.com/.img/bios/${createPath(
            allCharacter[back].name
          )}.png`}
        />
        <img
          className={`${characterImg} ${
            active ? characterActive : characterInactive
          }`}
          onClick={() => {
            navigate(`/world/bios/${createPath(allCharacter[front].name)}`)
          }}
          src={`https://dilancovak.com/.img/bios/${createPath(
            allCharacter[front].name
          )}.png`}
        />
      </div>
    </div>
  )
}

export default CharacterHome
