import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { faBahai } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Models
import { TierModel } from '../../../models'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Utils
import { getFont } from '../../../utils/utils'

// Styles
import {
  tierHr,
  tierImg,
  tierMoney,
  tierSlide,
  tierTitle,
  tierMonthly,
  tierContainer,
} from './styles.module.scss'

interface Props {
  tier: TierModel
}

const TierSlide: React.FC<Props> = ({ tier }) => {
  const { language } = useContext(LocalizationContext)
  return (
    <div
      className={`${tierSlide}`}
      onClick={() => {
        navigate(`/support/?tier=${tier.id}`)
      }}
    >
      <div className={tierContainer}>
        <img
          className={tierImg}
          src={`https://dilancovak.com/.img/emblems/${tier.emblem}`}
        />
        <h3 className={`${tierTitle} ${getFont('font-patua')}`}>
          {tier.name[language]}
        </h3>
        <p className={`${tierMonthly} ${getFont('font-lato')}`}>
          <span
            className={`${tierMoney} ${getFont('font-patua')}`}
          >{`$${tier.cost}/`}</span>
          mo
          <hr className={tierHr} />
        </p>
        {tier.rewards.slice(-3).map((rew, index) => (
          <p key={`Reward_${tier.id}_${rew.ENG}`} className={getFont('font-lato')}>{rew[language]}</p>
        ))}
      </div>
    </div>
  )
}

export default TierSlide
