import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'

// GraphQL
import { getGraphChapters } from '../../../graphQL'

// Utils
import {
  getFont,
  compareByNumber,
  comparebySeries,
  comparebyPublishDate,
} from '../../../utils/utils'

// Components
import DilanButton from '../../atoms/DilanButton'
import CoverSlide from '../../molecules/CoverSlide'

// Hooks
import useInterval from '../../../hooks/interval'
import useWindowSize from '../../../hooks/windowSize'

// Styles
import {
  navPrev,
  navNext,
  buttonGap,
  navButton,
  pbCorrection,
  centeredText,
  shiftingTrans,
  centeredButton,
  carrouselTitle,
  carrouselBlock,
  carrouselSlider,
  carrouselWrapper,
} from './styles.module.scss'

interface Props {
  localization: {
    title: string
    button: string
    slide: string
    latest: string
    spin: string
  }
}

const ComicCarrousel: React.FC<Props> = ({ localization }) => {
  const slideLocalization = {
    read: localization.slide,
    latest: localization.latest,
    spin: localization.spin,
  }

  const { width } = useWindowSize()

  const chapters = getGraphChapters()
    .filter((chapter) => chapter.published)
    .sort(compareByNumber)
    .sort(comparebySeries)

  const maxChapter = chapters.length
  const latest = getGraphChapters()
    .filter((chapter) => chapter.published)
    .sort(comparebyPublishDate)[0].id

  const [shifting, setShifting] = useState(false)
  const [centerSlide, setCenterSlide] = useState(0)
  const [slideProps, setSlideProps] = useState({ width: 360, offset: 220 })

  const shiftSlide = (steps) => {
    if (!shifting) {
      setShifting(true)
      setCenterSlide(centerSlide + steps)
    }
  }

  const renderSlides = () => {
    const slides = [
      <CoverSlide
        key="Slide_second_last"
        chapter={chapters[maxChapter - 2]}
        localization={slideLocalization}
        last={latest === chapters[maxChapter - 2].id}
      />,
      <CoverSlide
        key="Slide_last"
        chapter={chapters[maxChapter - 1]}
        localization={slideLocalization}
        last={latest === chapters[maxChapter - 1].id}
      />,
    ]
    chapters.forEach((chapter) => {
      slides.push(
        <CoverSlide
          key={`Slide_${chapter.series.prefix}${chapter.number}`}
          chapter={chapter}
          localization={slideLocalization}
          last={latest === chapter.id}
        />
      )
    })
    slides.push(
      <CoverSlide
        key="Slide_first"
        chapter={chapters[0]}
        localization={slideLocalization}
        last={latest === 0}
      />
    )
    slides.push(
      <CoverSlide
        key="Slide_second"
        chapter={chapters[1]}
        localization={slideLocalization}
        last={latest === 1}
      />
    )
    return slides
  }

  useEffect(() => {
    if (width >= 1440) {
      setSlideProps({ width: 360, offset: 224 })
    } else if (width >= 1280) {
      setSlideProps({ width: 310, offset: 179 })
    } else if (width >= 1024) {
      setSlideProps({ width: 300, offset: 282 })
    } else if (width >= 768) {
      setSlideProps({ width: 360, offset: 200 })
    } else if (width >= 640) {
      setSlideProps({ width: 360, offset: 264 })
    } else {
      setSlideProps({ width: 250, offset: 510 })
    }
  }, [width])

  useEffect(() => {
    setTimeout(() => {
      setShifting(false)
      if (centerSlide >= maxChapter) {
        setCenterSlide(0)
      }
      if (centerSlide < 0) {
        setCenterSlide(maxChapter - 1)
      }
    }, 200)
  }, [centerSlide])

  useInterval(() => {
    shiftSlide(-1)
  }, 3000)

  return (
    <div className={carrouselBlock}>
      <div
        className={`${navButton} ${navNext}`}
        onMouseDown={(event) => {
          event.preventDefault()
        }}
        onClick={(event) => {
          event.preventDefault()
          shiftSlide(1)
        }}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
      <div
        className={`${navButton} ${navPrev}`}
        onMouseDown={(event) => {
          event.preventDefault()
        }}
        onClick={(event) => {
          event.preventDefault()
          shiftSlide(-1)
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <h2 className={`${carrouselTitle} ${getFont('font-patua')}`}>
        {localization.title}
      </h2>
      <div className={carrouselWrapper}>
        <div
          className={`${carrouselSlider} ${shifting ? shiftingTrans : ''}`}
          style={{
            left: `${-centerSlide * slideProps.width - slideProps.offset}px`,
          }}
        >
          {renderSlides()}
        </div>
      </div>
      <div className={`${centeredButton}`}>
        <DilanButton
          type="ghost"
          handleClick={() => {
            navigate('/comic')
          }}
          className=""
        >
          <div className={centeredText}>
            <div
              className={`${buttonGap} ${getFont('font-lato', pbCorrection)}`}
            >
              {localization.button}
            </div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </DilanButton>
      </div>
    </div>
  )
}

export default ComicCarrousel
