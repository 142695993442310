// extracted by mini-css-extract-plugin
export var centeredButton = "styles-module--centered-button--eGRzq";
export var centeredText = "styles-module--centered-text--FNheX";
export var carrouselBlock = "styles-module--carrousel-block--k1OsL";
export var carrouselTitle = "styles-module--carrousel-title--9N4B3";
export var carrouselWrapper = "styles-module--carrousel-wrapper--NYnCV";
export var carrouselSlider = "styles-module--carrousel-slider--4esko";
export var shiftingTrans = "styles-module--shifting-trans--W6qew";
export var navButton = "styles-module--nav-button--4sE3+";
export var navPrev = "styles-module--nav-prev--Y6Jut";
export var navNext = "styles-module--nav-next--SZznY";
export var buttonGap = "styles-module--button-gap--DuZPA";
export var pbCorrection = "styles-module--pb-correction--NN7wG";